import React from 'react'
import { useActiveWeb3React } from '../../hooks'

import { AutoColumn, ColumnCenter } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon, CustomLightSpinner } from '../../theme'
// import { ArrowUpCircle } from 'react-feather'
import ArrowUpCircle2 from '../../assets/images/submitted.svg'

import Circle from '../../assets/images/loader-blue.svg'
import { getEtherscanLink } from '../../utils'
import { ExternalLink } from '../../theme/components'

const ConfirmOrLoadingWrapper = styled.div`
  width: 100%;
  padding: 24px;
`

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 60px 0;
`

export function LoadingView({ children, onDismiss }: { children: any; onDismiss: () => void }) {
  return (
    <ConfirmOrLoadingWrapper className="c-loadingModal">
      <RowBetween>
        <div />
        <CloseIcon onClick={onDismiss} />
      </RowBetween>
      <ConfirmedIcon className="c-loadingModal_loader">
        <CustomLightSpinner src={Circle} alt="loader" size={'90px'} />
      </ConfirmedIcon>
      <AutoColumn gap="100px" justify={'center'} className="c-loadingModal_info">
        {children}
        <TYPE.subHeader className="c-loadingModal_infoFooter">Confirm this transaction in your wallet</TYPE.subHeader>
      </AutoColumn>
    </ConfirmOrLoadingWrapper>
  )
}

export function SubmittedView({
  children,
  onDismiss,
  hash
}: {
  children: any
  onDismiss: () => void
  hash: string | undefined
}) {
//   const theme = useContext(ThemeContext)
  const { chainId } = useActiveWeb3React()

  return (
    <ConfirmOrLoadingWrapper className="c-submittedModal">
      <RowBetween>
        <div />
        <CloseIcon onClick={onDismiss} />
      </RowBetween>
      <ConfirmedIcon className="c-submittedModal_loader">
        <img src={ArrowUpCircle2} alt="" />
        {/* <ArrowUpCircle strokeWidth={0.5} size={90} color={theme.primary1} /> */}
      </ConfirmedIcon>
      <AutoColumn gap="100px" justify={'center'} className="c-submittedModal_info">
        {children}
        {chainId && hash && (
          <ExternalLink href={getEtherscanLink(chainId, hash, 'transaction')} style={{ marginLeft: '4px' }}>
            <TYPE.subHeader className="c-submittedModal_infoFooter">View transaction on Etherscan</TYPE.subHeader>
          </ExternalLink>
        )}
      </AutoColumn>
    </ConfirmOrLoadingWrapper>
  )
}
