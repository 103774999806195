import React, { useCallback, useState } from 'react'
// import { HelpCircle as Question } from 'react-feather'
import styled from 'styled-components'
import Tooltip from '../Tooltip'
import Questionicon from '../../assets/svg/question-mark.svg'
import QuestioniconLight from '../../assets/svg/question-mark-grey.svg'


const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  border: none;
  background: none;
  outline: none;
  cursor: default;
  border-radius: 36px;
  background-color: ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.text2};

  :hover,
  :focus {
    opacity: 0.7;
  }
`

const LightQuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  border: none;
  background: none;
  outline: none;
  cursor: default;
  border-radius: 36px;
  width: 24px;
  height: 24px;
  background-color: rgba(255, 255, 255, 0.1);
  color: ${({ theme }) => theme.white};
  opacity: 0.5;

  :hover,
  :focus {
    opacity: 0.7;
  }
`

// const QuestionMark = styled.span`
//   font-size: 1rem;
// `

export default function QuestionHelper({ text, mode }: { text: string; mode?: string }) {
  const [show, setShow] = useState<boolean>(false)

  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])

  return (
    <span>
      <Tooltip text={text} show={show}>
        <QuestionWrapper onClick={open} onMouseEnter={open} onMouseLeave={close}>
          {mode ? <img className="c-tooltip_img" src={QuestioniconLight} alt="" /> : <img className="c-tooltip_img" src={Questionicon} alt="" />}
        </QuestionWrapper>
      </Tooltip>
    </span>
  )
}

export function LightQuestionHelper({ text, mode }: { text: string; mode?: string }) {
  const [show, setShow] = useState<boolean>(false)

  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])

  return (
    <span style={{ marginLeft: 4 }}>
      <Tooltip text={text} show={show}>
        <LightQuestionWrapper onClick={open} onMouseEnter={open} onMouseLeave={close} className="c-tooltip_icon">
          {mode ? <img className="c-tooltip_img" src={QuestioniconLight} alt="" /> : <img className="c-tooltip_img" src={Questionicon} alt="" />}
        </LightQuestionWrapper>
      </Tooltip>
    </span>
  )
}
