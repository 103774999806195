import React, { useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import styled, { ThemeContext } from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { AppDispatch } from '../../state'
import { clearAllTransactions } from '../../state/transactions/actions'
import { shortenAddress } from '../../utils'
import { AutoRow } from '../Row'
import Copy from './Copy'
import Transaction from './Transaction'

import { SUPPORTED_WALLETS } from '../../constants'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import { getEtherscanLink } from '../../utils'
import { injected, walletlink } from '../../connectors'
// import { injected, walletconnect, walletlink, fortmatic, portis } from '../../connectors'
// import CoinbaseWalletIcon from '../../assets/images/coinbaseWalletIcon.svg'
// import WalletConnectIcon from '../../assets/images/walletConnectIcon.svg'
// import FortmaticIcon from '../../assets/images/fortmaticIcon.png'
// import PortisIcon from '../../assets/images/portisIcon.png'
// import Identicon from '../Identicon'
import { ButtonSecondary } from '../Button'
import { ExternalLink as LinkIcon } from 'react-feather'
import { ExternalLink, LinkStyledButton, TYPE, UniTokenAnimated } from '../../theme'
import mnyTokenLogo from '../../assets/images/mny.svg'
import { Break2 } from 'components/earn/styled'

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 500;
  color: ${props => (props.color === 'blue' ? ({ theme }) => theme.text1 : 'inherit')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const InfoCard = styled.div`
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 20px;
  position: relative;
  display: grid;
  grid-row-gap: 12px;
  margin-bottom: 20px;
`

const AccountGroupingRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};

  div {
    ${({ theme }) => theme.flexRowNoWrap}
    align-items: center;
  }
`

const AccountSection = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  padding: 0rem 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 0rem 1rem 1.5rem 1rem;`};
`

const YourAccount = styled.div`
  h5 {
    margin: 0 0 1rem 0;
    font-weight: 400;
  }

  h4 {
    margin: 0;
    font-weight: 500;
  }
`

const LowerSection = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  padding: 1.5rem;
  flex-grow: 1;
  overflow: auto;
  background-color: ${({ theme }) => theme.bg2};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  h5 {
    margin: 0;
    font-weight: 400;
    color: ${({ theme }) => theme.text3};
  }
`

const AccountControl = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 0;
  width: 100%;

  font-weight: 500;
  font-size: 1.25rem;

  a:hover {
    text-decoration: underline;
  }

  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const AddressLink = styled(ExternalLink)<{ hasENS: boolean; isENS: boolean }>`
  font-size: 0.825rem;
  color: ${({ theme }) => theme.text3};
  margin-left: 1rem;
  font-size: 0.825rem;
  display: flex;
  :hover {
    color: ${({ theme }) => theme.text2};
  }
`

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`

const WalletName = styled.div`
  width: initial;
  font-size: 0.825rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text3};
`

// const IconWrapper = styled.div<{ size?: number }>`
//   ${({ theme }) => theme.flexColumnNoWrap};
//   align-items: center;
//   justify-content: center;
//   margin-right: 8px;
//   & > img,
//   span {
//     height: ${({ size }) => (size ? size + 'px' : '32px')};
//     width: ${({ size }) => (size ? size + 'px' : '32px')};
//   }
//   ${({ theme }) => theme.mediaWidth.upToMedium`
//     align-items: flex-end;
//   `};
// `

const TransactionListWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
`

const WalletAction = styled(ButtonSecondary)`
  width: fit-content;
  font-weight: 400;
  margin-left: 8px;
  font-size: 0.825rem;
  padding: 4px 6px;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

// const MainWalletAction = styled(WalletAction)`
//   color: ${({ theme }) => theme.primary1};
// `

function renderTransactions(transactions: string[]) {
  return (
    <TransactionListWrapper className="c-accountModal_transactionsText">
      {transactions.map((hash, i) => {
        return <Transaction key={i} hash={hash} />
      })}
    </TransactionListWrapper>
  )
}

interface AccountDetailsProps {
  toggleWalletModal: () => void
  pendingTransactions: string[]
  confirmedTransactions: string[]
  ENSName?: string
  openOptions: () => void
}

export default function AccountDetails({
  toggleWalletModal,
  pendingTransactions,
  confirmedTransactions,
  ENSName,
  openOptions
}: AccountDetailsProps) {
  const { chainId, account, connector } = useActiveWeb3React()
  const theme = useContext(ThemeContext)
  const dispatch = useDispatch<AppDispatch>()

  function formatConnectorName() {
    const { ethereum } = window
    const isMetaMask = !!(ethereum && ethereum.isMetaMask)
    const name = Object.keys(SUPPORTED_WALLETS)
      .filter(
        k =>
          SUPPORTED_WALLETS[k].connector === connector && (connector !== injected || isMetaMask === (k === 'METAMASK'))
      )
      .map(k => SUPPORTED_WALLETS[k].name)[0]
    return <WalletName className="c-accountModal_connectionInfo">Connected with {name}</WalletName>
  }

  // function getStatusIcon() {
  //   if (connector === injected) {
  //     return (
  //       <IconWrapper size={16}>
  //         <Identicon />
  //       </IconWrapper>
  //     )
  //   } else if (connector === walletconnect) {
  //     return (
  //       <IconWrapper size={16}>
  //         <img src={WalletConnectIcon} alt={'wallet connect logo'} />
  //       </IconWrapper>
  //     )
  //   } else if (connector === walletlink) {
  //     return (
  //       <IconWrapper size={16}>
  //         <img src={CoinbaseWalletIcon} alt={'coinbase wallet logo'} />
  //       </IconWrapper>
  //     )
  //   } else if (connector === fortmatic) {
  //     return (
  //       <IconWrapper size={16}>
  //         <img src={FortmaticIcon} alt={'fortmatic logo'} />
  //       </IconWrapper>
  //     )
  //   } else if (connector === portis) {
  //     return (
  //       <>
  //         <IconWrapper size={16}>
  //           <img src={PortisIcon} alt={'portis logo'} />
  //           <MainWalletAction
  //             onClick={() => {
  //               portis.portis.showPortis()
  //             }}
  //           >
  //             Show Portis
  //           </MainWalletAction>
  //         </IconWrapper>
  //       </>
  //     )
  //   }
  //   return null
  // }

  const clearAllTransactionsCallback = useCallback(() => {
    if (chainId) dispatch(clearAllTransactions({ chainId }))
  }, [dispatch, chainId])

  return (
    <>
      <UpperSection className="c-accountModal_upperSectionWrapper">
        <CloseIcon onClick={toggleWalletModal} className="c-accountModal_close">
          <CloseColor />
        </CloseIcon>
        <HeaderRow className="c-accountModal_title">Account</HeaderRow>
        <AccountSection className="c-accountModal_wrapper">
          <YourAccount className="c-accountModal_contentWrapper">
            <InfoCard className="c-accountModal_card">
              <AccountGroupingRow id="web3-account-identifier-row" className="c-accountModal_accountDetails">
                <UniTokenAnimated width="56px" src={mnyTokenLogo} className="c-accountModal_animatedLogo" />
                <AccountControl className="c-accountModal_detailsWrapper">
                  {ENSName ? (
                    <>
                      <div>
                        {/* {getStatusIcon()} */}
                        <p className="c-accountModal_walletAddress"> {ENSName}</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        {/* {getStatusIcon()} */}
                        <p className="c-accountModal_walletAddress"> {account && shortenAddress(account)}</p>
                      </div>
                    </>
                  )}
                </AccountControl>
                {formatConnectorName()}
                <div>
                  {connector !== injected && connector !== walletlink && (
                    <WalletAction
                      style={{ marginRight: '8px' }}
                      onClick={() => {
                        ;(connector as any).close()
                      }}
                      className="c-accountModal_accountAction"
                    >
                      Disconnect
                    </WalletAction>
                  )}
                  <WalletAction
                    onClick={() => {
                      openOptions()
                    }}
                    className="c-accountModal_accountAction"
                  >
                    Change
                  </WalletAction>
                </div>
              </AccountGroupingRow>
            </InfoCard>
          </YourAccount>
        </AccountSection>
        <Break2 className="c-accountModal_break" />
        <AccountGroupingRow>
          {ENSName ? (
            <>
              <AccountControl>
                <div className="c-accountModal_addressActionWrapper">
                  {account && (
                    <Copy toCopy={account} fontSize="24">
                      <span style={{ marginLeft: '11px' }} className="c-accountModal_addressAction">
                        Copy Address
                      </span>
                    </Copy>
                  )}
                  {chainId && account && (
                    <AddressLink
                      hasENS={!!ENSName}
                      isENS={true}
                      href={chainId && getEtherscanLink(chainId, ENSName, 'address')}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <LinkIcon size={18} className="c-accountModal_linkIcon" />
                      <span style={{ marginLeft: '11px' }} className="c-accountModal_addressAction">
                        View
                      </span>
                    </AddressLink>
                  )}
                </div>
              </AccountControl>
            </>
          ) : (
            <>
              <AccountControl>
                <div className="c-accountModal_addressActionWrapper">
                  {account && (
                    <Copy toCopy={account} fontSize="24">
                      <span style={{ marginLeft: '11px' }} className="c-accountModal_addressAction">
                        Copy Address
                      </span>
                    </Copy>
                  )}
                  {chainId && account && (
                    <AddressLink
                      hasENS={!!ENSName}
                      isENS={false}
                      href={getEtherscanLink(chainId, account, 'address')}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <LinkIcon size={18} className="c-accountModal_linkIcon" />
                      <span style={{ marginLeft: '11px' }} className="c-accountModal_addressAction">
                        View
                      </span>
                    </AddressLink>
                  )}
                </div>
              </AccountControl>
            </>
          )}
        </AccountGroupingRow>
      </UpperSection>
      {!!pendingTransactions.length || !!confirmedTransactions.length ? (
        <LowerSection className="c-accountModal_transactionsWrapper">
          <AutoRow mb={'1rem'} style={{ justifyContent: 'space-between' }}>
            <TYPE.body className="c-accountModal_transactionsText">Recent Transactions</TYPE.body>
            <LinkStyledButton onClick={clearAllTransactionsCallback} className="c-accountModal_transactionsClear">(clear all)</LinkStyledButton>
          </AutoRow>
          {renderTransactions(pendingTransactions)}
          {renderTransactions(confirmedTransactions)}
        </LowerSection>
      ) : (
        <LowerSection className="c-accountModal_transactionsWrapper">
          <TYPE.body color={theme.text1} className="c-accountModal_transactionsText">
            Your transactions will appear here...
          </TYPE.body>
        </LowerSection>
      )}
    </>
  )
}
