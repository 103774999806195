import React, { useEffect, useMemo, useState } from 'react'
import { TYPE } from '../../theme'

const MINUTE = 60
const HOUR = MINUTE * 60
const DAY = HOUR * 24

export function CountdownPeriod({ exactEnd, callback }: { exactEnd: Date, callback: any }) {
  // get end/beginning times
  const end = useMemo(() => Math.floor(exactEnd.getTime() / 1000), [exactEnd])

  // get current time
  const [time, setTime] = useState(() => Math.floor(Date.now() / 1000))
  useEffect((): (() => void) | void => {
    // we only need to tick if rewards haven't ended yet
    if (time <= end) {
      const timeout = setTimeout(() => setTime(Math.floor(Date.now() / 1000)), 1000)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [time, end])

  const timeUntilEnd = end - time

  let timeRemaining: number
  let message: string = ''

  const ongoing = timeUntilEnd >= 0
  if (ongoing) {
    timeRemaining = timeUntilEnd
  } else {
    message = 'Withdrawal is now accessible'
    timeRemaining = Infinity
    callback()
  }

  const days = (timeRemaining - (timeRemaining % DAY)) / DAY
  timeRemaining -= days * DAY
  const hours = (timeRemaining - (timeRemaining % HOUR)) / HOUR
  timeRemaining -= hours * HOUR
  const minutes = (timeRemaining - (timeRemaining % MINUTE)) / MINUTE
  timeRemaining -= minutes * MINUTE
  const seconds = timeRemaining

  return (
    <TYPE.black fontWeight={400} className="c-withdrawModal_countdown">
      <strong>{message}</strong>
      {Number.isFinite(timeRemaining) && (
        <code>
          {`${days}d ${hours.toString().padStart(2, '0')}h ${minutes
            .toString()
            .padStart(2, '0')}m ${seconds.toString().padStart(2, '0')}s`}
        </code>
      )}
    </TYPE.black>
  )
}
