export async function getCirculation(): Promise<{
  success: boolean
  data?: { usdPrice: number; circulatingSupply: string; totalSupply: number }
}> {
  try {
    const response = await fetch(`https://api.vaiot.ai/token`)
    if (!response.ok) {
      return { success: false }
    } else {
      const json = await response.json()
      return { success: true, data: json }
    }
  } catch (error) {
    console.log(error)
    return { success: false }
  }
}

export async function getPrices(): Promise<{
  success: boolean
  data?: { ethPrice: number; mnyPrice: number }
}> {
  try {
    const response = await fetch(
      'https://api.coingecko.com/api/v3/simple/price?ids=ethereum,moonienft&vs_currencies=usd'
    )

    if (!response.ok) {
      return { success: false }
    } else {
      const json = await response.json()

      return { success: true, data: { ethPrice: json.ethereum.usd, mnyPrice: json.moonienft.usd } }
    }
  } catch (error) {
    console.log(error)

    return { success: false }
  }
}
